export function formSubmit() {
	var form = document.querySelector('#offer-form')

	let required = ['name', 'email', 'phone']

	if (form) {
		form.addEventListener('submit', function (evt) {
			evt.preventDefault()

			let formData = $(this).serializeArray()
			// Validate the form
			if (
				formData.some((element) => {
					return required.includes(element.name) && element.value == ''
				})
			) {
				return false
			}

			var formFields = {}
			$.each(formData, function (i, field) {
				formFields[field.name] = field.value
			})
			// Remove the default entries from the form
			delete formFields.planet

			// Add the action url to our form fields
			formFields['action'] = $('#offer-form').attr('data-action')

			// Send those values to our function
			console.log('values: ', formFields)
			$.ajax({
				url: '/.netlify/functions/step-submit',
				data: formFields,
				success: function (result) {
					window.location.href = '/questionnaire/' + '?e=' + encodeURIComponent(formFields['email'])
				},
			})
		})
	}
}
