export function fillParams() {
        let parameter = 'e';
        let e = '';
		var params = window.location.search.substr(1).split('&');
		for (var i = 0; i < params.length; i++) {
			var p = params[i].split('=');
			if (p[0] == parameter) {
				e = decodeURIComponent(p[1]);
			}
		}
        let emailField = document.querySelector('[data-email]');
        if(emailField) {
            emailField.value = e;
        }
}