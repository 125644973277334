import { Modal } from './vendor/bootstrap.esm.js'

export function initModals() {
    let modals = [].slice.call(document.querySelectorAll('.modal'));
    modals.map((modal) => {
        return new Modal(modal);
    })
}

export function exitIntent() {
    var exitHappened = 0;

    if(!document.querySelector('.modal--ei')){
        return;
    }
    
    setTimeout(function(){
        document.addEventListener("mouseout", function(evt){
            if ((evt.toElement === null && evt.relatedTarget === null || (typeof evt.toElement == "undefined" && evt.relatedTarget === null)) && exitHappened < 1) {
                
                if(!document.querySelector('.modal.is-open')){
                    (new Modal('.modal--ei')).show();
                    exitHappened = 1;
                }
            }
        });
    }, 4000);
}